<template>
  <div>
    <vx-card :is-refresh="isLoading">
      <vs-tabs alignment="fixed" v-model="activeTab">
        <vs-tab label="SPP Header"
                icon-pack="feather"
                :icon="errors.tabHeader.isError ? 'icon-alert-triangle' : null"
                :tab-color="errors.tabHeader.isError ? 'danger' : null">
          <TabHeader/>
        </vs-tab>

        <vs-tab label="Detail Pekerjaan / Barang"
                icon-pack="feather"
                :icon="errors.tabPekerjaan.isError ? 'icon-alert-triangle' : null"
                :tab-color="errors.tabPekerjaan.isError ? 'danger' : null">
          <TabPekerjaan/>
        </vs-tab>

        <vs-tab label="Informasi Pembayaran"
                icon-pack="feather"
                :icon="errors.tabPembayaran.isError ? 'icon-alert-triangle' : null"
                :tab-color="errors.tabPembayaran.isError ? 'danger' : null">
          <TabPembayaran/>
        </vs-tab>
      </vs-tabs>
    </vx-card>

    <div class="vx-card mt-5" v-if="activeTab === 2">
      <div class="w-full flex justify-end">
        <vs-button :disabled="isLoading || !isAnyUnsavedChanges" icon-pack="feather" icon="icon-save" class="w-full" color="primary" @click="save">
          <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
          <span v-if="!isLoading">Simpan</span>
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import SpkRepository from '@/repositories/proyek/spk-repository'
import { mapState } from 'vuex'
import _ from 'lodash'
import moduleSpkAdd from '@/store/modules/proyek/spk/spk-add.store'
import { convertToFormData } from '@/utilities/common/global-methods'

export default {
  name: 'SpkAdd',
  components: {
    TabHeader: () => import('@/views/pages/proyek/spk/tabs/TabHeader'),
    TabPekerjaan: () => import('@/views/pages/proyek/spk/tabs/TabPekerjaan'),
    TabPembayaran: () => import('@/views/pages/proyek/spk/tabs/TabPembayaran')
  },
  mounted () {
    this.listenKeyboardPressed()
  },
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    activeTab: {
      get () {
        return this.$store.state.proyek.spkAdd.activeTab
      },
      set (value) {
        this.$store.commit('proyek/spkAdd/SET_ACTIVE_TAB', value)
      }
    },
    ...mapState({
      errors: state => state.proyek.spkAdd.errors,
      isAnyUnsavedChanges: state => state.proyek.spkAdd.isAnyUnsavedChanges,
      dataTabHeader: state => state.proyek.spkAdd.tabHeader,
      dataTabPekerjaan: state => state.proyek.spkAdd.tabPekerjaan,
      dataTabPembayaran: state => state.proyek.spkAdd.tabPembayaran
    })
  },
  methods: {
    save () {
      this.$store.commit('proyek/spkAdd/CLEAR_ERRORS')
      this.isLoading = true

      const params = convertToFormData({
        tabHeader: this.dataTabHeader,
        tabPekerjaan: this.dataTabPekerjaan,
        tabPembayaran: this.dataTabPembayaran
      })

      SpkRepository.create(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            const errors = error.response.data.errors
            if (errors.approver) {
              this.notifyErrorUsingDialog(errors)
            } else {
              this.$store.commit('proyek/spkAdd/SET_ERRORS', errors)
              this.notifyError('Data tidak valid, mohon periksa kembali.')
            }
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.$store.commit('proyek/spkAdd/SET_ANY_UNSAVED_CHANGES', false)
      this.notifySuccess('Data berhasil disimpan.')
      this.$router.push({ name: 'proyek.spk' })
    },

    confirmLeaveOptions () {
      return {
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Ada perubahan yang belum disimpan, yakin ingin berpindah halaman?',
        acceptText: 'Yakin',
        cancelText: 'Batal'
      }
    },

    listenKeyboardPressed () {
      window.addEventListener('keypress', e => this.onKeyboardPressed())
    },

    onKeyboardPressed: _.debounce(function () {
      this.$store.commit('proyek/spkAdd/SET_ANY_UNSAVED_CHANGES', true)
    }, 500)

  },
  beforeCreate () {
    this.$store.registerModule(['proyek', 'spkAdd'], moduleSpkAdd)
  },
  beforeDestroy () {
    this.$store.commit('proyek/spkAdd/RESET_STATE')
    this.$store.unregisterModule(['proyek', 'spkAdd'])
  },
  beforeRouteLeave (to, from, next) {
    if (this.isAnyUnsavedChanges) {
      this.$vs.dialog({
        ...this.confirmLeaveOptions(),
        accept: () => {
          next()
        }
      })
      return
    }
    next()
  }
}
</script>
