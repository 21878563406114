import _ from 'lodash'

const errors = {
  tabHeader: {
    isError: false,
    messages: null
  },
  tabPekerjaan: {
    isError: false,
    messages: null
  },
  tabPembayaran: {
    isError: false,
    messages: null
  }
}

const getDefaultState = () => {
  return {
    // global
    activeTab: 0,
    errors: errors,
    isAnyUnsavedChanges: false,

    // tab header
    tabHeader: {},

    // tab pekerjaan
    tabPekerjaan: {
      items: []
    },

    // tab pembayaran
    tabPembayaran: {}
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  getters: {
    getTabPekerjaanItemByRowUuid: (state) => (uuid) => {
      return _.find(state.tabPekerjaan.items, item => item.uuid === uuid)
    }
  },

  mutations: {
    SET_ACTIVE_TAB (state, tabIndex) {
      document.body.scrollTop = 0 // safari
      document.documentElement.scrollTop = 0 // chrome, firefox, ie and opera
      state.activeTab = tabIndex
    },
    SET_ERRORS (state, payload) {
      const errors = payload
      const tabHeaderErrors = _.pickBy(errors, (value, key) => _.startsWith(key, 'tabHeader'))
      const tabPekerjaanErrors = _.pickBy(errors, (value, key) => _.startsWith(key, 'tabPekerjaan'))
      const tabPembayaranErrors = _.pickBy(errors, (value, key) => _.startsWith(key, 'tabPembayaran'))

      state.errors.tabHeader.messages = tabHeaderErrors
      state.errors.tabPekerjaan.messages = tabPekerjaanErrors
      state.errors.tabPembayaran.messages = tabPembayaranErrors

      state.errors.tabHeader.isError = !_.isEmpty(tabHeaderErrors)
      state.errors.tabPekerjaan.isError = !_.isEmpty(tabPekerjaanErrors)
      state.errors.tabPembayaran.isError = !_.isEmpty(tabPembayaranErrors)
    },
    CLEAR_ERRORS (state) {
      state.errors = _.cloneDeep(errors)
    },
    SET_ANY_UNSAVED_CHANGES (state, isAnyUnsavedChanges) {
      state.isAnyUnsavedChanges = isAnyUnsavedChanges
    },
    SET_TAB_HEADER (state, payload) {
      state.tabHeader = payload
    },
    SET_TAB_PEKERJAAN (state, payload) {
      state.tabPekerjaan = payload
    },
    SET_TAB_PEMBAYARAN (state, payload) {
      state.tabPembayaran = payload
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState())
    }
  },

  actions: {}
}
